import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);  

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Synex Coin">
              <svg className="w-12 h-12" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
<image id="image0" width="64" height="64" x="0" y="0"
    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAAAAACupDjxAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQflCwgTAAAmWSRGAAA590lEQVR42k29Z3BlW3Ye9u1z7gXuRc5ANxqpG6EBdAa6Gxno3C/NIx/F
oeUiJYpVVrkkSqJMl2TLlEWalmVWSS6WxTJdkqaUKMq0GExqhsMRJ3By4gRp3rwOaAA35xxO3nv5
x977YvrPO43GvWedFb71rbDPY/swjKG/8GdvIBiEIM6n3vl0TDACgSI/9f0f+iT/4K3K1wkAAWCP
Cv+ZIP/cHP4zeUFA39PP1QmMAIQeJl6q3zDGdj/blJesZ37qCwIgwIz07Dc+p34FfZGBq1+wAcM0
w+FL0W8CME0zFB6aNy8Jglt4FLMYiBGAuredsAECIcg8KDUEwAAguWNVmbrO7FTULVFc6SqCGADA
s+6eCEYAIEr387aS3DWuxtTzkHVhKAcwgIDian9KfQsP8dB0CiDGGLOnvSYIBhgLes2LAERd7L7y
AZAgQn50OeHL72g7OwlHgMDAePZhwgHAGBCU9s98dcvMTtFiIDAA9ZHptLql6905FfJSNC72FNWl
UdtsNJSEVNzwS1r9ZnvObYCIAcxeSQoGMBjMMi8SCaLc2OprIkFEBMTXB9McBMZQGlpOeiTtagVb
x4IBYECbXT+VyoTf2DnlYFJxuTt2Xf4Y9dGJjNZP/VbZUpesdnjmSaGYKG8VWkpyk1nXEhwAAwt6
hvMAGWAM5hQAAsXWh+MCQgAM4mzfKUlRKHmtpxBI+7Di1GxMSYXSzGhGma3RsxjTviRyh3FXKaV4
y2qoH/ve7TOtzkBsvhbKaYP21omvjRx0T6TBwBhD+0rNBshgzDDnAiIQ8dMDpyB1CHjZh4U6AcSY
iO+3auq7kdwwCpAqRHaroW+fWwmXtISOf/tECR5Ut+PaD+zBS0ltzObo3BkYAGJwjKXTjpFb8+02
AAaD/MUkyRuZ6w4nEMHNPE81wYhAoKa7nbBBAOAVD7Mt9RUi9aDQZNIPeeEo5qnvzu3mbTCp2/LU
cE4Ja4VWtGp5ZYVX1aVRvdqX1hI2LnZr9zThrCaENLI70lUBiDGYW4EtIAA0m49eeQQiACiMrJwF
AAGsgY2ko/DFqR298ZWRLbF5rMDDb+ydcI072c2mjvDKrA4O8Nr9lHoegdJ9vwgARGD19YKjnjMU
9A3lZSSjvZb1AWLM/CuFwBEgAkrROy8DQMoYXxtOCQZiYLmLlzI+CCBCPXLzjcIUFKcmNUo0en/E
DYt72rKivFnUWOMF18/kMxBRab9VkSpkwl89IxkyMFpLNUuqUGAmBYAxc3GxyF0iEJC8Mn0KQAAE
cbbnFqUbsMSdUImr22eWB9JQYJPZdGraDVfNjhva7JryK3jW3U5wtC4M5bQKeemgLKOdwRntKUp3
Eya8lTgBYIw5034TIGa2r4zVfA8EAp3dZ1mpKSDIPsrXGQEgnnxQqym8psReoy4NC5F/EPekOilz
kLO0hOW5/rxWbf9FDYy8dq/a1BIG5b2MBYDAWOOaNrIIeYN9RYAYGKyrSQHAHIltsrYbMAAUxB8V
GsoLqW3vxG2JNU51P22RIAAICg9jrpLQdu8fK9fzGrtvdLAjs11uq8vSKtdaDhr7pzqq4Vv3TwMZ
J+RLtQEE01opOjIx+JHxHEDmWJDcabqOTJB26cmpIzMuoTS4GvcZwAi1rhtxT4nS5ptKFMbKY5cS
TH51vf9KjGllVfdOuL48Dw6n6+ZrLSCzQzdeS9+DPd7biWTyrySJAYyx9mLVBswRsiu7Rd8lIgA1
bL0OpI4ZkmvDaSFvml4YzwYqlIsXZ+L6Ppk7fkXhTu46lbUKW+ElHTQOu3GqHk3U5sY6aMga46PK
+qx5M6cj2XTGusvKyM5ikmCOAjW6mRcugRij3NRl/X0k4jteiRgBRPH7otShDRu8JO8CkXmQlN/N
KH2QdrSEhcWwlrZ6YUB7JC9uuurHxFhlpV0HQAaEt3qmnoGZ7dWcJ4PTHeyuwhwlhtzofCmQ7kGx
mz1ppliVn3lYaEqOwzNH5abWRPphxpK/Are991r5ntfeOum4YW67Q2aKd+oq4VJQftBBfYb67TiX
RrYmogoEAlOIhaR0IGYtFXxzGESUXO6rBwEDATxx2KwCkta17b2YK4mK3dpN2UqFbvPgONAKGplV
BkdtcC6hBfSbO1pav7EdV78tvNbjU0fpCkF4NqG02bie0yk85EyiJo1MbCZtDgOAiG+Ktie/0Ms/
jtukRCz1r8XUl1f6rqY8qTZUB66eatTN3DA0BOZu6HwGNPsWtODtsLYguMUPXwXygwZrz/l1KaDg
OisKxtrr6UAa2Z7i5pB8yuxOw3cFAyPWsg+OA2VBSq0Op6XglL46lNN6S61Fc/I2jLIHGW3N3EGq
44a5ax1pyws9BXUpGt33XgoAjBjQvhFXWGONR5R3cpPYTEoaGfaqOSFvbzW2StyRWikNrmt4A8X2
vJIMCIrvWlVNlJKHlQZAMBjc5v4bBSqudf/8k9n9lDKbKO6UtRuiMrr0Rl4aCEIzSWXk5rW8NrJp
X/KaABkMPGLOepJAVM31gvBlnk0uj6UkUwCC9EGxQQRGLMg+yrXU/f3ig1OPwEBAvX9Z57bamKZV
gGfd08zLbx6+0W6I4lJfGgCDYKyljQxOyz9i5NUUBwGMtc3VwAMAhuzF6TL3AQai+I5XkgDIyLL3
VOpA27ubdCAjpcU2XkPVKLm1kGYtmdtexw2rY1M6zzUj14+1Zll+u1pXf2Gt60lpZGZdCHeMzMOT
WWVk84OikEjPEteiTZ8zBgY/9TjXkk8GlHqunXFpnsLYQkZlK+RnJ5PKaiJ1WFC5TeQeakoNlr3p
KEmosDSohCUSpV2Z84iBh6S/gaFxK60JpmnPWW1GYIB59UJF+IoHbLm2LwAQ7NqDN0puQmZ5NCXr
EqRuRopciZW871aUKH59T7khc9x7525YPEjqPJfda1W1kT2+Iss8Yqw134lksaTiXhjMldwVzGyu
9DZEAAbAz+1XfZVya+Fbb6RzgiG+HZTkj0XysFEjMBBjPPMw6ShkbERXT3TITkxpmgjHuf+m47SP
YjqfseYFGdYExlo3E8rI7elQRRs5iA4XGACY/cmNwAoEGIB2+14+8GSgZOYuaRyDn36UV0zJKx2l
LQIYCI61c8wVHmVXIgVlqsyGrV0M1eFO0LT9o5da+ajdKtgAMcEQaCODNW8ntQOZsm4CMwf83FbD
k+HLytGVIvcBMFB808zritxq7cVcWfs22EZcmQ2VoaUzYowxAOmDonbD82oFyF4npRWqDtx6pewA
cm+eEgBGjLUv+zUpNidNzAVjnqybzEFYjfslriRMz49Xg0BZ80GpqbwQlT6ZURiQm72Y0XiduRkq
6PK4fKSRxA423nTcMPdQw7jIX54+00a2x4ZyYGAgA8rIYLCmDV1ahbyBvpIUEHV2rQOBsVtGy5fY
7RYfnWsitTSaEVq1KKs0S8kHhbaSpRW6diJvg9LFMV2zw6sfHWtumNkQCo4ItRtlS8IoC8xZHcmt
Gyldz5jt5ZIrBURhbLYslN4SO5brEYiBWsH26w49iW/rNoVIP6g0NIOpPHjjqwSdX+rTBWfmvGhG
vX9dQ2CQPcq2lYBk3zoVAJhgrK0iGeCYT3aMHFxJKAGRXB6sc670tlMOVCgXxnRZDfDzQLHrB0ll
NjTCNzvmTO1VmlKFPP9Qs3sgs96vIdBrHb5WimDO0HgGYARiaN+QkWww6xJq6oamNxKuKgEpcQft
QGql6W3kufr25PWeLClObzf34450uGrPekLfP7c4nFZuGBSPTrQb+lvnaJh4UlE3pVb3yqnWUO2a
4omMBaFLHXZ9K6k93LSX874UEEF6q+36iisMLJaUhBTfa9WUe6Dacy3GVfttcTSnC9HEjvoVoM1u
a2uWR+bPUSr79omGwPLlnjwgK8fW5okgMAiG9nwgjcy4MaeNbBC7lDEvSFGc2lbFVxKmroxUA66+
+0nHmsgsjakaheK7XkURG557pHM1ivPDGeWRmZvolChN7+FHCgJF8W5B1uYEr2c6CQYQMaicDMas
maAhObIw7QkyZ5XvNYPbBaHsltikts8BRrDa+xo9gPi2V5af5ZmHuZbULGv79zsVZ2qnqa2ZOsp2
Hq04tv5K/Zhbt1XfkFh9vd0EybQUmknLH6J9LcUBgAkYzrJ5BdL3WGnwirYsT+3VPRUold7rnSjg
6cf5lrS35ezEXZVFilNzMf0bhadvZMkDv6I9koD4jWFVDVB7aCoNyIZiY+MskNZEa86Xgc+C0MUM
AEYQJg+ZTxqkLJuZH6lxWVs6lb1ioDAwc3ksRUySQ7t5qM1ZGllMqg8itanxDZa4p9IFmuFbx0o+
JmJHTZ1RardaDSW41z2rGBFjbZ2T0Z71dAvCtM0HZhvqRombZkv5Xl3czCsaRvEtv6ziBNWe9ZiK
juR6tKD7LsmHWd1KKFwc0yQwvziSVkqGn3oes5UbNu6eyRROrL7k1aWsLAhPa7i21tJcy2027rsO
k6KI9LblqDSSH5kvcem22rAAA9JLo2lVLiUOmnUlods47JR5qR1LU9b4YbOqW7KWffSR/G3hRBbi
igVRQ6EKMbTmvIaU1e+aymoVmtHi/YYPHwBjbnG/qEM5eXWgFnBiADnVh6pRy4gSOzqj+IWHaUuZ
s9Z3VdMtkekEPk+/80a6MmNUHla1CER1mapSQuaHLis4YmjfSHB1NW93eteD7fZGhZMPMKBl38t3
IPAeV5Uoq5ubxzpOg9SjfFOGcotvJnSyzqz1qIeG03r0QrmBZT34SMheHaPMOtOZuLaV8FSrvzmP
qjIy13DNYK2llJHNAaqG14rEOQDGyj3LRSVhkN6vex4RwJCd6cQpnObhmQuAgQrT02llWUoeVHR/
vxq5rnTFSqMrx8p9Gc/tKWJDLq2eSe8kNDYTmv+357ymcsjuMdUvMQeB3MRMmQWCgTFkZiYqijfY
td18oGI2sWHqBgsq0eunkoKzxN0OsfELD5K2kjC90qfVmdwMZ5UbMse991r5b302UpR1BgKomp0M
WNeTXMX0nN3uCIjEcm+D+ZJVJ25Emr7MaDV2I6fUKeKPKh1+kl4ZTQswMkgkjyoNTbfYvTeBamfG
D2uKVIvYu+km05btNI2pfDfvKAmbs6bKOSzoupBRRnZWZMPAHARA8dtGm8noEMlt21YS5iZmVSjD
Kz7uJDTEdr0yAQQ4tYOk7iXk56cSklmwIPPsVHU5vfz7P/RlI4GJ/H01QqPAva27aKy2Ics5YmjN
u015xaPDRQCQrY8gvWU5kMrys/sVz5fdicS1aF0hY9Pf18QTPPE0p6KsFrnWITbxu0EJYCAGyz18
oWxfN+7/UGmFBTVd/VF7dDQrKzviwVqnyLPWk7rjNde2AJgXAgBwS1t1T0nYbmzlA6lOim+5lsp5
xbFl/cxwWvtSn4xllobzmjGnjkp6AlGaWNS4k1kckemCEVo9l2OQIF250VCdONa+0F1Sl0H4ojIy
2StpAZgzFABA075VFio6qua1vPABMPIzhxVPI+Nqf4fIV/pkMc8Y4jtulUsvc6qPzrQfJDYNHVax
o4ou86hwjVcAxhh46+6Zzhe1W7L7RkB7wWkBIBg8OloAzEUSHAAqkZUyKWaTn5opSrlhNXZyurcZ
P2jrHEHp5ZGMAAOjIPswr+uSRuiW5ql09k5auUGQe3os+ysMorSrikKye2Z0SSq81bgyMpSRwWDN
N2yYBy1FZzIXp6rqEolrvTUlbDV8PSs0Mj5LqVkDsfiOW5FN8Laz1en95uZHddXul979SIFkC7df
KxvDoZsKJKl61a1rI0/0dIzcJfsyxMhZSpP5jNtMipVY62mRLxgAim0H7YAzYkDm4nRJCWs1H7wO
OoH6SBEEVhq+oltn0BScMdS7736k2ju5Kz26nkJlri8vZ+eidlcV6sRq14uONDJrzTmqLxT0DJZM
e6vlSoSh+B1uQ5afQXq/5gSyzkzc7GrIlg2r9NyQkzAG2M39mMvAwJC+2l9Q1I/nFMAwILU2mJRJ
EanDrJ7zUH47b0l9eobuqxI5N860kVvX0zqS5+tmpHS/GpAvTbjdduETIwa7ut8J5cSuZUmSw9KL
40liUi/VTjFPqT2ryplMAe6BBBgGnD4v1VRZX3/wUmdzv7ktMzsTjVnVciM4w4OqC8t8Y1bnZO+K
OdSyN4tCKs4pbZeVsKxOdzIKpP3sQdlXXcTYtl8CGAMxSl0dyQiAEfMLu8W2nFKw0oV5XasGyY+9
dGUXrT64ENNGbgzLWgQQ1btqQkKsvla1lZHbl4QmXkPmEFUjK0WigADWcG+XhOQNlB9dyEvcYW17
K9OpBp5lW2AQjECxQ7sq2VST7mZtqU2W2PY1v257+x8J6ZHpO26ns5m/1VacOvA7Yx5yrmu4RvNa
VuqGWeYgkJm8WFUIU+pdLpEO5eXBqvwpK/es5hRIOqWnb3REBKlORilMzWU82awTiXfPdLmUn74Y
k+am7MMzzc14We9kiNb4UF4Z2e0fU5FkcJrTcG0OSVFq4BJspieqKitT7C41lbCZ+fGyCuU623qt
M4rV3FfklBK3zKKkG3DqTz/UDhfftiuSbjn+ZqeWt0JrKs9Q5VbFUhI2lq2WMrJ9wahB7tcMQZKF
lhIrcTXSVEUKT+03XC3sBmuqQMlNL5wSwBhjqPavqUECxR9U6ypmK+OdPCfSz050mTU70slEpcVQ
mckJa3tTjZQFNW9KmkqMtdbUbFCSBZ7Ysm0poUhscoupar60V/A13zps2irnxTdCeVVTsNTVwazE
S6/wNKYbrvE9V7uhbXcUnt7VwwhQfiflyke3e/VImYLQnIR5MgTNyzpZDXK87LYmC0Fmt+kq3tD0
NzUj9AoP8lrY2ONyQzJWotiuU5HthqZx9ySQcSIS759oNyzNjqnkwksPOoTIczfUjIJqVx1dhjZn
WE3+O3MmjTqg6RbBqm+VAimWU9opByoVFwdXsio6mu5dHcpe7vmpSwADQ5B9WmjI7JJdHs4o33Pa
Dz7SsiT3q4rrtrpWO92y2uSIYt28cSepK/z6jbyn3LB1I+cBMAcZMYBQD+4UhGI2/p0CVyGRujyq
o6M4sJhXly3nwQuuzGm1j04dCcexx+WGXgKantduyHNPX6mknFvvzPWQ36i0Jad2zQVNtEVwVVfy
gq7HAJgjKvRQ6r1SUsym2Ltc0LwhtmnoUE4vD1XVZXF4/Y2aM6Hcsx6TNUqQe/tY57nYjq/nc23z
pm56pY46BJzXt1WTRtQXVHufyBnuK0sMZ/bkUA4wD/NQ1X96ekLTmfSlCxUVyiKxr6ODzu6Ltu5C
rJ+Tw9TKUFrGbzO6fqbazSL2PGGpHnzuarfihn5zu4M1rR49vRWVTTWNJTRX6zprV2+2GjDf5UWo
raHktZ465KJW4nqkrgLFyx/qUObJw6qj+Mfpnq3HOIgfthTYpe75FTVeceoPXgolYeJhRt21Nnqx
M0UprgUqJHzrTky1vMheT0lGBVHfiXtmc7dVB5P9uNhd3pYII2L3fUtJ2Grf19HhlB9kA53zHqZU
oLIg9Xbckvki8bFTW8hNjMrgyokCRr969EqFT3azfU6vd3R0WP2K6YO8nlnZshXwgnvHZld5v1NI
BMm9ps0CAAhS+3VXhXIluqYTXUNsphWFcGpHMR8wGIhsZ+84kHpjt8/UNJqlbht5MAIj1Aev6K2E
/OGpbuO4/Gpc9WiqK36DMcZA1FxCVVekAwvmQKu9ldUVt5vfrXoUEBic8l4hkDGDzMyU5qz58fmC
znmhvZeCkSAz6hcvzcQlm8luGiU1URHJZynd9MrcCVTxa4sbHaypzvTLgRdEbVMNIIiqW2rfQaC8
bPazmnErrSvHdutenlMAMDS8jTxxCSbxG931QNbK8fW+uu6Azc6fEgHgQHzPLssJWezdpKXc0K09
VNMvUOpJp7N4pTcvTU+U36ipuYRHV/WyS2DtnOihWdYcYCgMLycD3Vdh1wsSbFipb7lAgVx5jO84
liT7dLbDbTWaP71r5hQaivS7p5JvOe2DNz7J6KgOLms0dK3tV7KGoexu3lIfC6pbCeWGjQt9urdk
RdfUUJ6EOQggPX8ppTZ4kB9aKElmw1KzkxJsGPzsg6InJQySD6quKsxO3io0VOPFDu4fB8TAUJic
TanKBanznk5lcjIllzmCyv6xYomwzJWEHPaJ6q2apRRbmlNLP0RyDJFYH8zqrbv0wkhFgU38ened
ydmi1djLmDcfP9y+PpFPPajPDPsOAD/9zrE7ujBWRfgqv81z0g3ju+GR0SpnwMVL9nv24Pj4+Hi4
ifBTIyML6FbvYs9EtwzNyly0JNUZtHdO5RYiIX/bU9DNlmwA6H7v1Yd6YhP+WOaN3RYAEP3gZaIt
pwa495d2hyVoPR3c/ut9n/x5AMD1G7/zb3a+8pcx/jUDX/iVZMDAgOFfeY9202D4R39Rh8Nv/4/4
qV9j/+g3GYgBxt//OfGXvyg3rqMPv1GRjWzj1sRn5GYbCw0/+U/S4EZXBAS4n1m9DCKAGPxPz12K
9DAAsP/42kQ0CoAQ+VvvDpc//zt/8B07HP7+ix/grWUAwA/Kv7At/g+pud0f7zeICKh8EsyQw5qg
VndqtVrNAv7Dt/E3JmVxPPIB/uiLKoHZf34/LKsc/gPjlkKdoPaVR70AgFA3yCGw5uee2hkQYwTY
/+ktJy8sAKh+9vHXSLgA/a1d8R//8MOai4GftPGlyduRn/8bDAA++/fYZ7/LYADN/vfffM4iAkMV
6DIEAHzzp40PXn4o97x+6VN9f+e/B4HY/zBU/3pIo2EhufENeeV/6ccKihAGuR++9f8FAIyh7u5u
gFD+8sGofGhQ9c82hyMR6ZHfvN/fEwaMn8Zv/93p2d4QGp9IQvzBZ/HOMgHA0Sz9M5DgwH/A/Hur
3eo7sNKlAC744zujQhCAV/8MP3GHGHDvJ/ArH93T1qePepbVVeuzj/oVngfH2ScGAOP6QHekCwBS
333Ypz+S/uZGf7QbAPDq+F6kx8REP77tfPL2ZJ8JEOD9SsX8m4wA42/jz5e6QAL47Bex+3QyrCy3
MxtWUdf6wlP5VfiNuPEPuwnhXzW+/Ps/6LmiJeTfvDGi9jvz33k3pH7ofxc7AIzv3ezrioRAwOs3
RxH9mdcnd3siYQCgbzXvRHsND5hC9U/uDfUaAAHF/xtvrYHo7XXv7wSbJgDgl1q9t97pl0+AT74z
aKovi58dqM2Cf4C1vwr83FX7fyL62rUhfbfWNx+HpYTiRf6IaQm/PHwTME5fXY+GoyEQ8L3GXkh/
5jvl29GoCQD0pz1Xo73VU/yVC0h++3CwBwARfqsQ+mvdCP0CfvfsUzenZX/jH2Nz/O2IXO5LfuXt
iJbwG31r8q5f+BT+2/WLv4BfjwH2t/bC+m7J04darK/0X1eXgfOluWUY9GHuWqQrYoCAr5j3DP2Z
L5lr0R4DAMQn52cjff+Ypv7gMV4c7/dG4DuWVf4NvLVivr/o/p9of/HRAAAR/MvvmEfGsvqKP6/v
hSWjhfjMcjQIgiAI/kGj/xf/l94P/wUA5BJ3gSAQAOg7XbdUbvE/d3Nam9768uK8OcDSF8frZAQE
Rok7mlmCzjZYEwEAFsQe1t3Xtd2Bj22nvjlz8VXV8QPf//5PDw5+9deH/sWfAMWF0ebPsn/9Injx
8fHvfrkuzOmP4xONkyP71rplr6+trc2ys6Ljuq5bsp/MLPL/Kh0GgOJSKNN0u0wAlHyojx14xae6
C0pBYd0cAEsu9zWJBQAT8R1NOsDju5YtJXTzR0X/21+9MXbpJzY/lah7LBwKMd9/uhB61PgZ3wBi
T90fZ78dG6ix7Y3/N1zrmv44PtEUZ+/NXRl79uzZs2dvP/6n/W0wxtiLdybZv/vnQTcDgNy9HwTd
UYkw+eevFWNpe/uvNMUISuYAgMRttMgIABakDxqqIQUvfVDxpIRNazeN2L9Lrw7Ofrz+eipihrsi
kY9+cvgO+/VPi24gyL5/h/12JorvPZ5a/bejjYWfxCeasGs/NV77TjKZTMZP//17A81oNBpd+Otd
iPyOQ10AELwabvepu7XprhqTUqVPcQWAuDkIQCQ2PQsIAOYUD/Nt9Rm7cpgPSPZsQjdzzPnBbxVv
9O1cHkp7AcDgPWK1X6wHXQbQCP0YfrtsgH/48dncf+4Z+yl8ogmU765+42d+7/d///f/7W9R8VG+
Rcz4vxa/ODnFvux3GQD8mrmpC3zkZyb1inBuprOET0aEAWR9dm2sSwJ2/usPOuGf/NZeX0+XdPni
nZ5ew/s3jz+Pp2OPJEb+Th3f7g4zmwD8gNBjAvjeb+KXoqEu9QUOWJjJUzTW5x5GQf/1nvvL/xJ/
bYO1AaCN1vztDl5/cW5RXfpfGV7vCNjdDYBqn785EO7uAgixDx9EdeH10audvp4QANDn2VpPL0P5
p15jL7kV7QLgWuhfHzU8DsAnzEUIwK+e9f+TTAdPERnTwqY/PDRn/h5+8/SfZiL/cCRwAZuz3vOY
hfvpw1H9YF+6NK8ujXBXNwDkv3arrysSBggfZR506w99q7UR7TEl2EwsRqLM8H4XC18en+uWGPad
67Ndhk2ABTyZDRPQ/gXx9IMsYMiH5A8GNBr+l2Dz13pPfxPWr2LrZ3otEhaipv0nz3r0zWp/9lw/
Tftr8xeUgJGu7i4AlPjwTjQcCQHAt9xd/aX4k/BapNcAAP/3Vy5FutHDEVif3BnoNgGg+fmDMSPg
5HDgyx+MmAR87v9h/3svEJZf4X34MKqx9Ys/sSf+Zwf49Bfxi6um3SYzQsh9+23973R6rPGaWt+9
PCUFvBXpioQB0Kv47Ug4aoKAL/Rv6A+JP5pe6I4yTNyA9Yd3R6JdPR/DR5WTb2x1dxsA8IPKYRdz
YQE4+fIHfYyA/7Uw/reBQZWKv1/c6WKAcF139F38wZcB0C+7w//dlOuhFwA+bJzThj8XG/qy9eHi
FACYV6YrxIQAkJu6WCEjIABnW+gcpTk5siwKLn5qzU+U9hOLv7JDv/SROOsyAgr+m/6v/PnpAyvH
Dbfvb7Lf/frM5bhvg7LvzAH/qrcl8Ph65aW3N9wzNnkh6QafWKn/vC0AVKP3lr9TsBRhSuzpVRZQ
6o6nrsmvXfXaQOirD5dfEdkcwJefLL2mqEWA96n3bV3ttP7oAzsQMJ8/p3LX341A/Mbne23xvYtW
hAOA93t/6axssW4G0B/+3O1vA/jjzzwH0mOXkj5w5z+q51xo/uwD/Gp5+ysCwG+8f+nv/8BRzQb/
0z/2e3rU6/7Zfl01LETzh9dYDmZ/YrmnASMggBLXQxKw4acf6p4ZswtPMl6jGB0N93SH7K/98u9Q
KGI4YcOKvM+++n1Ypa0XxCY+sP8oQ6/fLaXRbX77uW/989jdlnN/pm1blsWtZvvfT/4T/t3/LbjR
VQDgp+932SctISPNsQ5eaNxwq7dz+tCJ11jxW2wa4bdzcdezBcC633uTcT0LALvw8DMlQDL0le3P
NO2g6+K48A8+VbcdAkR7uhoJewEA3DuJ9/TIfunFH/81rz8MMLSc8ff/tFHDkAlc6f4665e9sN6/
+McZAI7FBsyelI7wQ/pSB5imZ7+jm+3G4FrcHICI3w0sYgEAnthtuWABgGZ7N+nKHiXK3deS4EEt
k80bSwWOAGBd9clGvzylk0aUdYMDQLNqtLoMAAi7bdw/aSPKgIoddhWA+MW3XvokmhTtJuptKDxL
/8j2dSt0Oa8nE25j0RwAgsSW5UCaNrdXCUAcQM08kEfUGEPmylAJAQBktps2EQeY745EOQQAlIaH
idRlMKg8I+TmbvacIWKAsWo0YqjmUSN8+xU1udkP5vYGgSSgInWQ6szzGwMXdGcRbtUcIsDL7DQ8
KaFd3iv5jDjA8oO39dkgih002owDoNJBkpMQQNDqeZ6UEtrNo64mBAGg8sCVJgcAUwRvdl8FEQMM
XmN5SO82ZFb7Th30m4DZmGiE1Lna+kZMTy6oOjbS6cP65hQnwCls1Xxp5ZZ1PxcwLgCWWFh+Q2CM
ATz9NBEQB0R1bqAqIAiWcHd6igICcET7g5QNQQA5rbeZ3NwLu15yqB1hAPN874O4bq3Gtr/nS5Jl
NMfrXVqxl/SiCKg6E+lY3JwyAgDt+nYxAAsAVmXXStwICAwnNwZTatfbwnqawCHqsVt1X8Br+eg5
edyuEwQc8ppPTn0IgBwqvZdpEQBmepYYMQiAx/1g41UgBCcD/n8ZbfUzADA8FvFUnJQvdnW2r0Vl
iem/mNMSVxr2ZpEbxAHkI2sFwTgBeH0YFAEDYMjdoAaREK5zzHONph0gGglO383YRORQuGFungkI
kANRfyaHZSbnXmSzwAGPi1QmVHYc140yt2neUqw4VBv0DH3edtnu7BsG1WUolDPnCYwDqOBmkcvz
u7mxpQJnnACReF6oyxO1lH0SD4jAyIl228S6e7sY7MKzMw/koCuUmruUAQk4rLtubMhRXNiDfWBU
BHzOmDXaZTPGotSC/04gnYwZ9Qu6Nynq6+XOYq5XX+Gyd2M+qpPElWLPak4wcDAkpy8WpUc6jf2E
LZes7J5LJUEUikT5W6PGQMQUxFATu6eCdUVDwMl9s0pE0aiJ9NxMigNg0Wg0+ajcoq5oNBoN/Wwj
iEYYi0a7Um+p+ZjZfWFbjzs992qusxXt1le9FgCYN4frZLAAQGZstkwGF2A4WxqpSMWWJy8UXGKM
AdnDoisgiLHCx8qWIBCIZSeWEiTj+/hZrSlIMABn91ixszL13okjez75568VBDv29ku9Nzc8oEO2
HbnYwRd4zXW7DcCsLve0iCEAkJybqCvnO90INQgBgMResyV7hLy2lxAEIubkniV9YhAATm8NFAQT
APzE2ylX9j3E2eOSPuTWMo5eBAQwNEK39RJdaX5ATzEKq3Ud37XJaK0jodO61rIAsy92I9wWEptj
q6NlIQvQswcNW4ADorqVczmBMapemCwIggBq7n6CEwQx0JvHdhUQANr1x6eBxGs/+7QDK+nLE0kp
V3Y9opNE8iituIJoXM3qzFFdcTpHWGG31+sOzH6K3SWLGAmATq/31ITBCQjST9IBkQDqsyHXlU3n
2K7bFIAglhtcT3FigoDg9J2sJc/DlLvvxAQEwNC2d/XpdRw/aJfVUPd5Z5em1tkEd6LDeuLCG1er
3rmE7nrVNfsZP7vn2TA4ZxBv7obrwggAWO6tHAcEkN1PmXJ0KGJv5x0BELHY4mSeiAkATvZ53CcB
AMlL8ykBwRhDaWglIQdkxOPvnUnF+NVHL1XXrT6ujxujPu9ogHH5ynmgUDtYr5gDxIL4fdcVhhCA
ON12Len0xcvdTcFIwOu7UFUSOoW34p4ABOjNllkhMAGGhr9/wpkAgLNb0RKRYAAlb/fn1CaIZe2q
Fdfq+FRKKS5/q6jX01srel0XzZ7pzqIiqEWr5gQn5iX3mw5J0yaeFFzGOIDkYTYgJgi5g6qn9nCr
7kEsAIhAr5+0mnJHD/nhyxl5UJ7eHLUb8qAtTj9erMolOhTmxlNC+V5RjY1467L2PbdrslMeV6f7
y+cSNg1z0uAEN7nfcMkMCPBKR0lB4IAfLBcFMUGi8jBOTG655vpuylBGcPY874BBEFhsfTQv5MHu
2JO8IwMlOPkLr2z16o/Yg5IkC6K0/1r1NVpjhh5xNWaoM4svL/HmuYQN8wIZHHDTRyVf6rDRM18U
AAeKd6ueICaoPrCYg5rcxecuZQSYALMLzxK+DGWc7POaDBS3+DTmkQADWs7OG/2ah8KTV/KyMTqo
I7m5qhf7qLlW1B1hXl+vuucSmnMCjAN2/iDPZVpObzi2jOrWRopATCB2q6/C1OTueLOvAGKCUHf2
Y0IGCn/ztGhJ0zadvVMhBznZ5WG1/4LGwIzGmq2M8j0/PKrD18N8vsP6vauFzvEBmE/LMtW1rfsZ
ASMAKHuUFoJBoLrC2oIAQcfvWnUlIb164FeJGIEVBtaTSkIv8yzpyVAuDt48JUEMhLNn+Zbak0rp
mSV3ltRxVjSvNLSuGlPnDKsZmdPeCZjXxivEGAerDF0qCxgcsAZGG1wAAulnKZ+IIIIXP15sQ84W
+fHzalu2GxOLE3n5CgS0rKMzhdLJeyNJKWtQeHzs6Z7VrjpWUdULvhDBbEFLUrta7xi2OjHcCRqz
ujxQI8Y4kLrtOZLa5A5ygogRue79hCCACefkYxlHLf+48XczjnxfxJtdVBUclnqux7nsqL54q1SX
kVwbvpRRfta42CXhGs15dboR7Sno4AiCxQ4CUvmK0eGD/bENahPAgfRRVhAxDt49XSVBTFB+aiZH
BEA0c28lfFnWo118S76DAOL1kd0kmelSl8ezEgP56cfeeFLC1FGxpUfZO2ofyh4nvWJvXS3o5ZDm
8GAHX3hjtamw2xyg+OOiSwY4nK6ZqgAxjvxe2SMiQ9Dpfk9RDU+sh/FASVizD88k2PixpwVbvSHk
9D4ryzrP8naOpZFF5eBYvQ7G75pQFMeZLesDyX2DncWC2tJ5Inbd1aKvBIRf3EsRAIHcfbnVIUTj
bkYQgQn64XOnRgQGke/aSAhDfqwQvZkUBAKc/OOULyUUZx8rNKUb5q8M5YUAgNpst34PR+VWRrqZ
Oy5svfW61NJpjjsrGnaAVveCtLg5ADT6pysCjATZVyskiEFUFoZqJAiGCH74TqNFRAAlJ5YyZMjX
MCQvzmcIIELTPohxGShe+uMvlGnPnhba8tUWmaNTtcvCMVRVZwpnKwpIeHA5r13P6p06T3PV8Ym8
FhDpew1fECNR2agHgggQp7ttm4jAuPv6Y0WbCIzodHWsQExSgLP14YIcPpYi15KqLG4EO68kGgaF
x8dyRdzDvI6T5nxJ7Tuce2F7Inx+VHWBOvv4VJ7vrmgBqXa7QESMROt2noiIwT97u+QKIhjCjr2X
dQAyiF7f7aqCBSAwHN83qwATQGpmJqOycvrKSFaqsD4xmZNGLt4tOEqFPeG2UuF8uRMcV0ual1Fz
rdgpS3h91WkpAdG4IlwiYlRZDFskiBjZmXeTHhFgiGbuXVn3E3915DUZOAODON73WtIPz673lgiC
AHbyrNSUpk08ytpy2c66qg91eJeqSoWThg5uLzxV7Gy4m7O5DkS7zlrZVQKitlEQRMRE8nHBU6/9
KD5Nypfm8Fr1SdwngFFw8rhuMyEYAcHZ06oMYDp5FNQkWeDZd15zQQB48/6pxM3aUtOR+vKmHNlk
gjOvvRD1y74GQ9QvnicUtMJXctycDACgPc9cEoDhlPczRILAqNw+SgYEYkyU3cO4IDAm3PjTosuE
YADc9NOcQyCAn/24ft9Ls/dKWspVWeE1OeK3Z8pKGjbSVCocMdv6iK2z2IlealxtdN7UgOrYRN5c
kMeJC4cZQUTEysYt6UWMcthJcAIYE/nw/bg8y2oVnqZ9JkVoV45Sks84uecngdR8crehjFzcOZEr
Nq1ZvcniLFQVKtoLFe1u1tA5RHu0+COUujzbb86EfQ7AiVwukSCQkRpbzpIAMSbSPbeT8utEamg9
KQBiolF/nOBMPnIt2EoEBCJUo9cSnAQAKjw+lrhijUWUClmfpQ7F9narK2+4u6klqS+fq60+MdjJ
w+D1ZXOezEAASN9kLSIBxt5cWpDpzRDx8aspAcCAiM0tpkEMEBVvLxlA7xRei0vnS9w2KvKqOT5a
UEbeiEsVetM1rt4kMltR75dqL3YaCZwWzulLddlpdyR0HfOgRiFfADg9ajqCABivFqcKBBCYOJ2f
yUl705urE3kigIl8+E6Gy3YhktdHMrIsjj1PujK9pR/HPA4A7jDanAAEE66vFDcGW51r7e9tdCqR
6VBNyxT4K/lzOtgy3yl7FAoE4CeeFmXdbbxYGysJMGIGf31tpAgAjMTrjf6KTMuZkatpoaz8+q1q
gyAA17obl0YOcCUjFdaaL8pZX6i/rRTnT1fVVWvxvMRsLZc7183BifOEAjPYTXNZjVipZ3lPAu6L
O31lAsAM/nKzVxW14uW2WYM85j43kyVpZX7y7okPIqCwatQlwuR3Mi4nAM6kOovpzVbVopoz7Ur1
IogO1nX0utHJwrmRr1D9XMBQ91KWEAoIaOUfZ3x5kO6j+12yxmD85S6rEwhM8FeHokECTOBkfagI
KaFl77yR8Zt+dCaHo8Qv5aUKjR5LCAB8zPO13cZrWoVLNf2mJ9Qvex0w5O2r5wnFHM3MT+eBkE9A
vfIo7UuX++Eeq6vXS7144DcBkCGC1w+tNoEYieP7oTKTLldY6i8QEeBEZ/KCE4DK7QznBIBPNaTm
2GhDqBfHLGjM4d1jVa1C4a1kO1TGipwnFHMcp7eG8mBGQEC19SAZyBODHx3wBgAw5r1+3LLkIVzv
5FnNFiAIcfx+owEpYex5xiIBIHuQ8AUHILp6pDz+BWVjd16dCwB1DzY0eVhsuJ2N1tFOlwuozurX
48AcB17c7S0RzICAkr+rDlWKF0eOfLkMc968XbVlADuxp0WHAIjg5Kdfe7JxHjS2YoIEIMRCTqrQ
mitKzfWEpDeKAdNRlrUXSwpzRGi80lFbfaXR6bBSc62uGfUkgT7a7S4pP8yze2lODIz5L5+0FcO1
489Vm0JY2adpTwBEVu75K8GIAJSXWE0IAMWtpE8cgDfZDGQ6GWtKScVUlVRw9HW19WGnK21Hq9A3
5zKdOHGMRWVx8wIAenFEFTDTB5CN3k7JVOe/flq3CABDM/1WxpcLWM3y03gAYgLlwctpOU1H5slp
IARA5nhJpuQu05U2vlSTAjqzdaE5zXxJKVOYU+UO5tVnQ+dEoXZBvcbQvEAM4B8+9mtgZgAgNbQu
jzgz9/hxzZHvC2vkn6Uln6Ga+/yVIEAgdmA1ZUvGHRgpCUFA9U5SCAEgNNimAAANQdoY0WhLieVO
CFt74YJjd2q59pVzMKTaqtsAAHMaYCDx0ROnSTADAPELizLxMDv2RG5dG1StPU4GRGAChYm7rwkg
ovg7p4EgASB/eMqFAPiAsCkAwC80pDeGh1UcB5fKpI8wXiwLVS3Tpc6bA2H3TXXOjsELVnM+AHNN
9neDl0+bLUgJT+dn8vLsl3X6XB40YlS29xMBCBA4vtVVImIcFi3lVPLoGagIDiAYrwlOAJ9sBdLG
08rG3rSlodCesz3dQZoN2h320lh0OmCIxsiFDAHmL6qXcvgvnzcswAgI7PjqeIkYGJgde1aU2/mi
gPtJLiV88U7eJiJC9qBkEwkA5c0EFwTYl2rSxoPC5xwAn7B9adrwQJPUurk5rvMd8flO2wgcVzId
aVFZMsuAKd47lY01//h51QIzOIDX1xRDY63Uk4InABgi27sRIxCIgtxbbziRAFW2T0kIAvzxwCIO
0JDDKQDQFZW2RqSnrVS4UFQMA9aifhUG2peoda7C6e7z5mBgr1VsmCi/fRoQMcB986zkEDN8AK9v
99ZBILBm/lHGB4GYSM6uJogIArXhiwVBglBbdlskBAB3vigEAX3w5cHV8TrJZHKhJiX1x7mtozc6
UNdeyBfOe1lkrZbOW2+t/rm0MEcrtecxmT3cs+cFBzB9gF5thRoERmC18iO5B050vBMqEoEI8SdZ
l0MAle0TQRyAdTkvhAC6ey3phDNVwQUAf6YhKQSM4boOE2exqMAarWnWOo+TgfHOTBEoz/cVzAkq
t5+dcjAQ7MSzvAeEAoJ4tWvInqhRaR5K5i/oh+8ULSIIiOa9OBck0FpqWSQEQL2eRxwwxpuCC4BG
PYnZ6AtZ8sJZqJCGwuGujlTu5ZLfQejGknveXxWttZY5zqhoPT7j8sWYqcd5D8z0wfjLHaPJCIBR
8HYT8tWiQfK9E05EAuUbdluQANqrGYEAgNnlEJf4QhxAtMtR5wbH6lIuMWS2hR5ALRS0CtujkQ7t
gs8WMp2/wDJXzEkGFOyjM7mp3849zviSOfCXe0ZTLrfnQvdjRASiZnQ5I4gEUL4f50IQ6jdyPnEC
QgOW4ASasLgIAJijDemE3lxZKIebqmgb29Pc6qhwseydq/BS5Jw0oDotM0nRPUiq00qFhxlOzAgA
/mLbVPOs1PRaHERMIHFQtYQggcZK2xKCA129TS4EYIy2hRBAP/kUAPBnFOCIES7THpzLtUC/8Zam
i1qb9kS01tEaOcvlH2lRV8wLDAwoBjtqTtCoPsxwkMEB/moHDfly4DcboSqRgKDsYUwIEkDrWloI
QXBnK1xwgMbbnAugO2oTJ0BMWupsWXiwobrHvZGWlspasB0tlb30Iypsj06ckwa45gwBDCgEu+pF
G/XqgywnSAl3RYsIxIJXb+cdQRBozIbrgkigcTPrExdwZyucAkBMtYTggDlsyfDtNR11LPhSVaY9
8NmSDhPqGqsow8Me6aue10mNJafTQgLMK/K3WIFvqd52tfog68vOOn+1J5ryDdC5x2ccAgLZp8ck
iANdA3VBATBoByIAMG4JEQA0VZfnPUJDLSmXP932peLcWcvVUlmX6/65CkvnKvTCPxon5l9VJ2lQ
oLsZ1ViuHOY8SD88ftpuExGJ2sh4hRMJuBPhOglBaF/NkAiAARGIgIBhP6AA4FNNAR+AmFZOiJ5I
SykuMljTYRIM9OniBM7wYOVchbWF7vPa3ezfS3ICY0BBbOTk+8/r5YNcICMlOHk/5RERE4mHGZ8I
AuWdMxKCw50vceKEAeaJgIB+wxWcgKHAp4AAfqmhzxxNVWVAw79c1KwQ7hV9+AiwVipeJ+EJ52qx
02kwubebVC/NLIr7WWmARvUo64MxDjiFR2eCiIhbN9NCEId9uWETBUC/cIQQ6At5ggsgEnEEF0Bv
2BEBAehnjky+3nxNUht4U4Gtw8SZNDRDhDs8dE670ByfTHeKprGiu5uSpxOp5G9l5VG4WvUoFcjp
RHVgtsCJIEqbJYcECXgLBUGcEOq1BOeIRh3OORDt9QPBgfBAS5q2q78pFUeDhq0sa46XNWOAmMtr
YdFerjnnRKa+6nbGEOMoudu6QVp0djNc+eHTOJc6jO/WHUEQ1LiRJCKOxq0kF1wg1OcIzhHptYUI
gPBgGzwAMNkQggOgC9oJQ8MqmcBdLHOttvaCdw7Wg2PFcxW63VdS6jEMwzBef2u/J2SahmkYx18/
7A2bpmEYiW+8FQ4ZoS5AfHqjO2waJs76ooZhGODFXsMwAC6PJfnMYAyAEyaEGWCHDBgA0A6HmFzb
KQ+Y6sqpDTF9YEAkL5qdwwNvxoY718BLY1Vtq5iTIFSdvSwnEAiV5l5WPmFu8EoGAONoXog2uCBB
fDYvwAXC/S0hAoSHbMEDmCOWED4gppscAQHjtkLoQaHODvIpz9E2njq3cWu50QFrt3/ifAgB7q0W
ZUlpmIZpsJOv7vZ1mSHDNIyzr+73hkOmYYS+0Hs5HDJDYeCLK+GwaZh4MWEazATKXQZjDPKNo+Cm
1CRxJgc6VkSe80KzlzFDq9CUKilEI4Ze4fXyF8zOOu/rodHwuQrj1etSoeZFxsBQrR7mOREYUGkd
pOS729+8k/SJAOEN9dWEEFyMc4sLDjHe5DyAOeJwHoBNWQH5BIz4gQznPhUlbLImmQ3ETFm5Ix/p
anRU6C6WAn3t9U3m+HmOq6/L1x4Y4ZBpGIaR+uz2QChkMMaMk++9HQmZpmFan7/bHQ6ZJsO3Fk3T
NBhiwyGTMfCAMcj/2QUDuCDphG4YMAC0u5Ximt2m0mCDRZQTIjdqmHrHuNb8ERW+Ghj/ES+snq3K
DbmFkGkaBjPyf7o9ZJqmaRjm99N3u0zTNEI/pMku0zAMNMsDhmkYyEZCUhZpUyblBGBIAQ0mBZT/
Bbd7mKEOlw4YaousEO7rhAniU+HOdTuzHDbOJfwQ10wA/z+Pl4Mp9FAZXQAAACV0RVh0ZGF0ZTpj
cmVhdGUAMjAyMS0xMS0wOFQxOTowMDowMCswMDowMMC4zf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIw
MjEtMTEtMDhUMTk6MDA6MDArMDA6MDCx5XVBAAAAAElFTkSuQmCC" />
{/*
                <defs>
                  <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="header-logo">
                    <stop stopColor="#4FD1C5" offset="0%" />
                    <stop stopColor="#81E6D9" offset="25.871%" />
                    <stop stopColor="#338CF5" offset="100%" />
                  </radialGradient>
                </defs>
                <rect width="32" height="32" rx="16" fill="url(#header-logo)" fillRule="nonzero" />
*/}
              </svg>
            </Link>
          </div>

          {/* Site navigation */}
          <nav className="flex flex-grow">
            <ul className="flex flex-grow justify-end flex-wrap items-center">
{/*
              <li>
                <Link to="/signin" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Sign In</Link>
              </li>
*/}
              <li>
                <a href="https://t.me/synexcoin" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span>Telegram Group</span>
{/*
                  <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>
*/}
                </a>
              </li>
            </ul>

          </nav>

        </div>
      </div>
    </header>
  );
}

export default Header;
